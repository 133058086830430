<template>
  <b-nav-item :to="{ name: 'settings' }">
    <div class="header__nav-item">
      <span class="header__nav-item-icon d-inline-block">
        <feather-icon
          icon="LSettingIcon"
          size="18"
          :fill="themeColor ? themeColor : ''"
        />
      </span>
      <p class="d-inline-block">
        Settings
      </p>
    </div>
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      themeColor: localStorage.getItem('storageThemeColor'),
    }
  },
  setup() {},
}
</script>
