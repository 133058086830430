<template>
  <div class="navbar-header d-xl-block">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
              width="100px"
              height="60px"
              class="brand-logo-img"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style scoped>
@media all and (max-width: 1200px) {
  .brand-logo-img {
    max-width: 250px;
    max-height: 100px;
  }
  .navbar-header {
    max-width: 300px;
    max-height: 100px;
  }
  @media all and (max-width: 742px) {
    .brand-logo-img {
      max-width: 200px;
      max-height: 50px;
    }
    .navbar-header {
      max-width: 200px;
    }
  }
}
  @media all and (max-width: 400px) {
    .brand-logo-img {
      max-width: 170px;
    }
    .navbar-header {
      max-width: 200px;
    }
  }
</style>
