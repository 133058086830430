// MAIN SECTION MENU ITEMS PERMISSIONS =========>

export const MAIN_HOME_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Home',
}
export const MAIN_ORDERS_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Orders',
}
export const MAIN_WAREHOUSE_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Warehouse',
}
export const MAIN_INVENTORY_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Inventory',
}
export const MAIN_ACCOUNTS_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Accounts',
}
export const MAIN_CATALOG_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Catalog',
}
export const MAIN_REPORTS_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Reports',
}
// MAIN SECTION MENU ITEMS PERMISSIONS =========>
// =============================================>
// SETTINGS SECTION MENU ITEMS PERMISSIONS =====>
export const SETTINGS_GENERAL_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'General Settings',
}
export const SETTINGS_USER_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'User Settings',
}
export const SETTINGS_CUSTOMER_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Customer Settings',
}

// MUST BE CHANGED WHEN SETTING PERMISSION LIST WILL BE ADDED NEW PERMISSIONS
export const SETTINGS_CATALOG_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Catalog',
}
export const SETTINGS_ORDERS_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Orders',
}
export const SETTINGS_INVENTORY_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Inventory',
}
export const SETTINGS_FULFILLMENT_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Reports',
}
export const SETTINGS_REPORTS_NAVIGATION_PERMISSION = {
  action: 'view',
  resource: 'Reports',
}
// SETTINGS SECTION MENU ITEMS PERMISSIONS =====>
