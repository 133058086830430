<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <horizontal-nav-menu-header-link
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
  },
}
</script>
