<template>
  <div class="navbar-container main-menu-content p-0">
    <horizontal-nav-menu-items :items="menuItems" />
  </div>
</template>

<script>
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
