<template>
  <div class="navbar-container d-flex align-items-center justify-content-between">
    <div class="responsiveBlock d-xl-none">
      <!-- Left Col -->
      <div
        class="bookmark-wrapper"
        data-test="navbar-logo"
      >
        <slot name="logo" />
      </div>

      <!-- Right Col -->
      <div
        class="d-flex align-items-center justify-content-end"
      >
        <b-nav-item
          data-test="navbar-settings"
          :to="{ name: 'settings' }"
        >
          <div
            class="header__nav-item "
          >
            <span class="header__nav-item-icon d-inline-flex">
              <feather-icon
                icon="LSettingIcon"
                size="18"
                :fill="themeColor ? themeColor : ''"
              />
            </span>
          </div>
        </b-nav-item>
        <brooklyn
          :show-text="false"
          data-test="navbar-warehouse"
        />
        <b-link
          class="nav-link d-inline"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="18"
          />
        </b-link>
      </div>
    </div>
    <b-navbar-nav class="header__nav-bard d-none d-xl-flex nav align-items-center justify-content-between ml-auto">
      <!-- Left Col -->
      <div
        class="bookmark-wrapper d-flex align-items-center"
        data-test="navbar-logo"
      >
        <slot name="logo" />
      </div>
      <!-- Right Col -->
      <div class="d-flex align-items-center">
        <!--        <dark-Toggler />-->
        <settings-button data-test="navbar-settings" />
        <!--      <locale />-->
        <!--      <search-bar />-->
        <!--      <cart-dropdown />-->
        <notification-dropdown data-test="navbar-notification" />
        <brooklyn data-test="navbar-warehouse" />
        <user-dropdown data-test="navbar-user" />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItem } from 'bootstrap-vue'
// import Locale from './components/Locale.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
// import { getUserData } from '@/auth/utils'
import warehouseModule from '@/store/settings/warehouse'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
// import DarkToggler from './components/DarkToggler.vue'
import SettingsButton from './components/SettingsButton.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Brooklyn from './components/Brooklyn.vue'

export default {
  components: {
    NotificationDropdown,
    BLink,
    Brooklyn,
    BNavItem,
    // Navbar Components
    BNavbarNav,
    // Locale,
    // DarkToggler,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    SettingsButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      themeColor: localStorage.getItem('storageThemeColor'),
    }
  },
  created() {
    this.$store.dispatch('horizontalMenu/getWarehousesList').then(() => {
      this.$store.dispatch('listModule/operationHoursDisableList')
    }).finally(() => {
      this.loader = false
    })
    this.$store.dispatch('listModule/settingsDateFormat')
    store.dispatch('horizontalMenu/getMe').then(response => {
      const { data } = response.data
      store.commit('app/SET_PERMISSIONS', this.getActions(data))
    })
  },
  methods: {
    getActions(permissions) {
      if (permissions.length === 0) {
        return []
      }
      return permissions?.filter(item => item.subject === 'Allow Switch Warehouses')
    },
  },
  setup() {
    const MODULE_NAME_WAREHOUSE = 'warehouse'
    if (!store.hasModule(MODULE_NAME_WAREHOUSE)) store.registerModule(MODULE_NAME_WAREHOUSE, warehouseModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_WAREHOUSE)) store.unregisterModule(MODULE_NAME_WAREHOUSE)
    })
  },
}
</script>
<style>
.nav-item {
  list-style: none;
}
.navbar-container {
  display: flex;
  align-items: center;
}
.responsiveBlock {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
@media all and (max-width: 364px) {
  .responsiveBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}
.header__nav-bard {
  width: 100%;
}
</style>
