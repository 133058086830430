import {
  MAIN_ACCOUNTS_NAVIGATION_PERMISSION,
  MAIN_HOME_NAVIGATION_PERMISSION,
  MAIN_ORDERS_NAVIGATION_PERMISSION,
  MAIN_WAREHOUSE_NAVIGATION_PERMISSION,
  MAIN_CATALOG_NAVIGATION_PERMISSION,
  MAIN_INVENTORY_NAVIGATION_PERMISSION,
  MAIN_REPORTS_NAVIGATION_PERMISSION,
  SETTINGS_GENERAL_NAVIGATION_PERMISSION,
  SETTINGS_USER_NAVIGATION_PERMISSION,
  SETTINGS_CUSTOMER_NAVIGATION_PERMISSION,
  SETTINGS_CATALOG_NAVIGATION_PERMISSION,
  SETTINGS_INVENTORY_NAVIGATION_PERMISSION,
  SETTINGS_FULFILLMENT_NAVIGATION_PERMISSION,
  SETTINGS_REPORTS_NAVIGATION_PERMISSION,
  SETTINGS_ORDERS_NAVIGATION_PERMISSION,
} from './navigationItemPermission'

export const mainMenuItems = [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    ...MAIN_HOME_NAVIGATION_PERMISSION,
  },
  {
    title: 'Orders',
    route: 'home-orders',
    ...MAIN_ORDERS_NAVIGATION_PERMISSION,
  },
  {
    title: 'Warehouse',
    route: 'home-warehouse',
    ...MAIN_WAREHOUSE_NAVIGATION_PERMISSION,
  },
  {
    title: 'Accounts',
    route: 'home-customers',
    ...MAIN_ACCOUNTS_NAVIGATION_PERMISSION,
  },
  {
    title: 'Catalog',
    route: 'home-catalog',
    ...MAIN_CATALOG_NAVIGATION_PERMISSION,
  },
  {
    title: 'Inventory',
    route: 'home-inventory',
    ...MAIN_INVENTORY_NAVIGATION_PERMISSION,
  },
  {
    title: 'Reports',
    route: 'home-reports',
    ...MAIN_REPORTS_NAVIGATION_PERMISSION,
  },

]
export const settingsMenuItems = [
  {
    title: 'General',
    route: 'settings-general',
    icon: 'SettingsIcon',
    ...SETTINGS_GENERAL_NAVIGATION_PERMISSION,
  },
  {
    title: 'Users',
    route: 'users',
    ...SETTINGS_USER_NAVIGATION_PERMISSION,
  },
  {
    title: 'Accounts',
    route: 'settings-customer',
    ...SETTINGS_CUSTOMER_NAVIGATION_PERMISSION,
  },
  {
    title: 'Catalog',
    route: 'settings-catalog',
    ...SETTINGS_CATALOG_NAVIGATION_PERMISSION,
  },
  {
    title: 'Inventory',
    route: 'settings-inventory',
    ...SETTINGS_INVENTORY_NAVIGATION_PERMISSION,
  },
  {
    title: 'Orders',
    route: 'settings-orders',
    ...SETTINGS_ORDERS_NAVIGATION_PERMISSION,
  },
  {
    title: 'Fulfillment',
    route: 'settings-fulfillment',
    ...SETTINGS_FULFILLMENT_NAVIGATION_PERMISSION,
  },
  {
    title: 'Reports',
    route: 'settings-reports',
    ...SETTINGS_REPORTS_NAVIGATION_PERMISSION,
  },

]
