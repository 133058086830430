<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-flex align-items-center">
        <feather-icon
          style="margin-right: 7px"
          icon="UserIcon"
          size="18"
          :stroke="themeColor ? themeColor : ''"
        />
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.username }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <div class="d-sm-flex d-none user-nav" />
    </template>

    <div data-test="navbar-user-profile">
      <b-dropdown-item
        data-test="navbar-user-profile"
        :to="{ name: 'user-update', params: { id: userData.id } }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item></div>
    <b-dropdown-divider />

    <div data-test="navbar-user-logout">
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logoutRoute"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { BDropdownDivider, BDropdownItem, BNavItemDropdown } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      themeColor: localStorage.getItem('storageThemeColor'),
      avatarText,
    }
  },
  methods: {
    logoutRoute() {
      this.$router.push({ name: 'auth-logout' })
    },
    // logout() {
    //   // Reset ability
    //   this.$ability.update(initialAbility)
    //   useJwt.logout({
    //     token: this.token,
    //   })
    //     .then(() => {
    //       useJwt.clearUserStorage()
    //     })
    //     .catch(error => {
    //       this.errorNotification(this, error)
    //     })
    //     .finally(() => {
    //       this.isSending = false
    //     })
    // },
  },
}
</script>
