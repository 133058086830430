<template>
  <div
    id="app-content"
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <div class="content-body container-xl">
        <slot name="header" />
        <div
          id="mainBody"
          class="main-body mb-2"
        >
          <PortalTarget name="body-top" />
          <transition
            :name="routerTransition"
            mode="out-in"
          >
            <slot @updateMenu="updateMenu" />
          </transition>
          <div class="body-footer">
            <PortalTarget name="body-footer" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {},
  methods: {
    updateMenu(active) {
      console.log(active)
    },
  },
  setup() {
    const {
      routerTransition,
      contentWidth,
    } = useAppConfig()
    return {
      routerTransition,
      contentWidth,
    }
  },
}
</script>
